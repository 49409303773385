<template>
  <main class="container">
    <ContentHeader :title="content_header.title" />

    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <div class="page__body">
      <div class="page__body__header">
        <div class="row align-items-center justify-content-between">
          <!-- <div class="col-4">
            <label
              for="text"
              class="has-checkbox page__body__header__label text--capital"
              >Show disabled apps
              <input
                type="checkbox"
                id="text"
                class="form-"
                @change="toggleStatus()"
                v-model="showDeactive"
              />
              <span class="checkmark"></span>
            </label>
          </div> -->

          <!-- <div class="col-3">
            <select 
              @change="getAllExtensionApp"
              class="select select--md"
              v-model="filterStatus"
            >
              <option :value="null">All</option>
              <option :value="true">Activated</option>
              <option :value="false">Deactivated</option>
            </select>
          </div> -->

          <div class="col-12">
            <button
              type="button"
              class="button ml-auto page__body__header__button button--black text--capital"
              @click="add"
            >
              <span
                class="d-inline-flex mr-2"
                data-toggle="modal"
                data-target="#createNewApp"
                >add app</span
              >
              <ion-icon name="add-outline" class="text--regular"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <table class="customtable w-100">
      <thead>
        <tr>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 40%"
          >
            app name
          </th>

          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 20%"
          >
            provider
          </th>

          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 20%"
          >
            state
          </th>

          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 20%"
          >
            action
          </th>
        </tr>
      </thead>

      <tbody v-if="loading && extensionApps.length < 1">
        <tr>
          <td colspan="12">
            <div class="d-flex align-items-center justify-content-center p5">
              <div
                class="spinner-border"
                style="width: 3rem; height: 3rem"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <span
                class="text--black text--600 text--medium d-inline-flex ml-4"
                >Please wait</span
              >
            </div>
          </td>
        </tr>
      </tbody>

      <tbody v-else-if="extensionApps.length > 0">
        <tr v-for="app in extensionApps" :key="app.id">
          <td>
            <a
              href="javascript:void(0)"
              @click="edit(app)"
              data-toggle="modal"
              data-target="#configureApp"
            >
              <div class="align-self-center">
                <span
                  class="table__body__text table__text py-1 d-block text--capital link--text"
                >
                  {{ app.name }}
                </span>
                <span
                  class="table__body__text table__text d-block py-1 table__text--small text--capital"
                >
                  {{ app.description ? app.description : "" }}
                </span>
              </div>
            </a>
          </td>
          <td>
            <span
              class="table__body__text table__text py-1 d-block text--upper"
            >
              {{ app.provider ? app.provider : "" }}
            </span>
          </td>

          <td>
            <span
              v-if="app.state == 1"
              class="table__body__text table__text py-1 d-block text--upper"
              style="color: red"
            >
              {{ "disabled" }}
            </span>
            <span
              v-else
              class="table__body__text table__text py-1 d-block text--upper"
              style="color: green"
            >
              {{ "enabled" }}
            </span>
          </td>

          <td>
            <div class="tbody-child dropdown">
              <button
                class="button button--grey text--capital text--small d-flex align-items-center"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Action
                <span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="edit(app)"
                  data-toggle="modal"
                  data-target="#configureApp"
                >
                  Configure
                </a>
                <a
                  class="dropdown-item"
                  v-if="app.state == 0"
                  href="javascript:void(0)"
                  @click="openActivationModal('disable', app.general_app_key)"
                >
                  Disable
                </a>
                <a
                  class="dropdown-item"
                  v-if="app.state == 1"
                  href="javascript:void(0)"
                  @click="openActivationModal('enable', app.general_app_key)"
                >
                  Enable
                </a>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="openActivationModal('uninstall', app.general_app_key)"
                >
                  Uninstall
                </a>
              </div>
            </div>
          </td>
        </tr>

        <!-- <div class="pagination">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="text--capital text--regular pagination__text">
              row per page:
              <span class="d-inline-block text--primary" style="padding-right:15px">
                <select @change="changeLength" v-model="size">
                  <option :value="5">5</option>
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="30">30</option>
                  <option :value="50">50</option>
                  <option :value="paginations.totalCount">All</option>
                </select>
              </span>
              <span class="d-inline-block text--primary">{{
                !paginations.hasPrevious ? paginations.currentPage : size * (paginations.currentPage - 1) + 1
              }}</span>
              -
              <span class="d-inline-block text--primary">{{
                paginations.hasNext ? size * paginations.currentPage : paginations.totalCount
              }}</span>
              of
              <span>{{ paginations ? paginations.totalCount : 0 }}</span>
            </div>
            <div
              class="
                pagination__buttons
                d-flex
                align-items-center
                justify-content-between
              "
              v-if="paginations"
            >
              <button
                class="button button--sm text--regular"
                @click="changePage('previous')"
                :disabled="!paginations.hasPrevious"
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
              </button>
              <button
                v-for="page in paginations.totalPages"
                :key="page"
                :class="{
                  'text--white button--primary ': paginations.currentPage === page,
                }"
                class="button text--regular button--sm ml-2"
                @click="changePage(page)"
              >
                {{ page }}
              </button>
              <button
                class="button text--regular button--sm ml-2"
                @click="changePage('next')"
                :disabled="!paginations.hasNext"
              >
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
            </div>
          </div>
        </div> -->
      </tbody>

      <tbody v-else>
        <tr>
          <td colspan="12">
            <div
              class="d-flex flex-column align-items-center justify-content-center p5"
            >
              <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

              <span
                class="d-block text--danger text--600 text--medium mt-3 mb-5"
                >No data available</span
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Create App Modal -->
    <div
      class="modal modal__right"
      id="createNewApp"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="formCloseButton"
                  aria-label="Close"
                  @click="clearForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Store App</h5>
          </div>
          <div class="modal__body">
            <form class="form">
              <div class="form__item">
                <div class="form__item mb-0">
                  <label class="form__label">Source URL</label>
                  <div class="row">
                    <div class="col-9">
                      <input
                        type="text"
                        class="input form__input form__input--lg"
                        placeholder="Enter url"
                        v-model="form.loadUrl"
                        v-bind:class="{
                          'input-required': formErr.loadUrl,
                        }"
                      />
                    </div>
                    <div class="col-3">
                      <button
                        class="button form__button form__button--sm w-30"
                        :disabled="!form.loadUrl"
                        @click="loadExtension"
                      >
                        {{ loadXml ? "loading..." : "load" }}
                      </button>
                    </div>

                    <div
                      v-if="isLoaded"
                      style="padding-top: 30px"
                      class="col-6"
                    >
                      <label class="form__label">App Name</label>
                      <h5 class="py-1 d-block text--capital">
                        {{ form.name }}
                      </h5>
                      <!-- <input
                        type="text"
                        class="input form__input form__input--md"
                        placeholder="Enter name"
                        style="height: 10px !important;"
                        v-model="form.name"
                        v-bind:class="{
                          'input-required': formErr.name,
                        }"
                      /> -->
                    </div>
                    <div
                      v-if="isLoaded"
                      style="padding-top: 30px"
                      class="col-6"
                    >
                      <label class="form__label">App Provider</label>
                      <h5 class="py-1 d-block text--capital">
                        {{ form.provider }}
                      </h5>
                      <!-- <input
                        type="text"
                        class="input form__input form__input--md"
                        placeholder="Enter name"
                        style="height: 10px !important;"
                        v-model="form.provider"
                      /> -->
                    </div>

                    <div v-if="isLoaded" class="col-12 pt-5">
                      <label class="form__label">Description</label>
                      <h5 class="py-1 d-block text--Capital">
                        {{ form.description }}
                      </h5>
                      <!-- <input
                          type="text"
                          class="input form__input form__input--md"
                          placeholder="Enter name"
                          style="height: 10px !important;"
                          v-model="form.description"
                      /> -->
                    </div>
                    <div v-if="isLoaded" class="col-12 pt-5">
                      <label class="form__label">Extension Points</label>
                      <h5 class="py-1 d-block">
                        {{ form.permissions }} {{ form.extension_point }}
                      </h5>
                    </div>

                    <div v-if="isLoaded" class="col-12 pt-5">
                      <label class="form__label"> Access</label>
                    </div>
                    <!-- <div v-if="isLoaded" class="col-4">
                      <label class="py-1 form__label">Permissions</label>
                    </div>
                    <div v-if="isLoaded" class="col-8">
                      <h5 class="py-1 d-block">{{ form.permissions }}</h5>
                    </div> -->

                    <div v-if="isLoaded" class="col-4 pt-4">
                      <label class="py-1 form__label">App ID</label>
                    </div>
                    <div v-if="isLoaded" class="col-8 pt-4">
                      <h5 class="py-1 d-block">{{ form.app_id }}</h5>
                    </div>

                    <div v-if="isLoaded" class="col-4 pt-4">
                      <label class="py-1 form__label">App Key</label>
                    </div>
                    <div v-if="isLoaded" class="col-8 pt-4">
                      <input
                        type="text"
                        class="input form__input form__input--md"
                        placeholder="Enter key"
                        style="height: 10px !important"
                        v-model="form.app_key"
                      />
                    </div>

                    <!-- <div v-if="isLoaded" class="col-4 pt-4">
                      <label class="py-1 form__label">Username</label>
                    </div>
                    <div v-if="isLoaded" class="col-8 pt-4">
                      <input
                        type="text"
                        class="input form__input form__input--md"
                        placeholder="Enter username"
                        style="height: 10px !important;"
                        v-model="form.username"
                      />
                    </div> -->

                    <!-- <div v-if="isLoaded" class="col-4 pt-4">
                      <label class="py-1 form__label">Password</label>
                    </div>
                    <div v-if="isLoaded" class="col-8 pt-4">
                      <input
                        type="text"
                        class="input form__input form__input--md"
                        placeholder="Enter password"
                        style="height: 10px !important;"
                        @change="validatePassword"
                        v-model="form.password"
                        v-bind:class="{
                          'input-required': formErr.password,
                        }"
                      />
                      <span>Min:10</span>
                    </div> -->

                    <!-- <div v-if="isLoaded" class="col-4 pt-4">
                      <label class="py-1 form__label">Confirm Password</label>
                    </div>
                    <div v-if="isLoaded" class="col-8 pt-4">
                      <input
                        type="text"
                        class="input form__input form__input--md"
                        placeholder="Enter confirm password"
                        @change="checkConfirmPassword"
                        style="height: 10px !important;"
                        v-model="form.confirmPassword"
                        v-bind:class="{
                          'input-required': formErr.confirmPassword,
                        }"
                      />
                    </div> -->
                  </div>
                </div>
              </div>

              <div class="form__item mt-5">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    :disabled="isLoaded == false"
                    v-if="!isSaving"
                    @click="saveForm"
                  >
                    save change
                  </button>
                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="clearForm"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Configure App Modal -->
    <div
      class="modal modal__right"
      id="configureApp"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="formEditButton"
                  aria-label="Close"
                  @click="clearForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Configure Store App
            </h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="updateForm">
              <div class="form__item">
                <div class="row">
                  <div class="col-6">
                    <label class="form__label">App Name</label>
                    <h5 class="py-1 d-block text--capital">
                      {{ form.name }}
                    </h5>
                    <!-- <input
                        type="text"
                        class="input form__input form__input--md"
                        placeholder="Enter key"
                        style="height: 10px !important;"
                        v-model="form.name"
                    /> -->
                  </div>
                  <div class="col-6">
                    <label class="form__label">App Provider</label>
                    <h5 class="py-1 d-block text--capital">
                      {{ form.provider }}
                    </h5>
                    <!-- <input
                        type="text"
                        class="input form__input form__input--md"
                        placeholder="Enter key"
                        style="height: 10px !important;"
                        v-model="form.provider"
                    /> -->
                  </div>

                  <div class="col-12 pt-5">
                    <label class="form__label">Description</label>
                    <h5 class="py-1 d-block text--Capital">
                      {{ form.description }}
                    </h5>
                    <!-- <input
                      type="text"
                      class="input form__input form__input--md"
                      placeholder="Enter key"
                      style="height: 10px !important;"
                      v-model="form.description"
                    /> -->
                  </div>

                  <div class="col-12 pt-5">
                    <label class="form__label">Extension Points</label>
                    <h5 class="py-1 d-block">
                      {{ form.permissions }} {{ form.extension_point }}
                    </h5>
                  </div>

                  <div class="col-12 pt-5">
                    <label class="form__label"> Access</label>
                  </div>

                  <div class="col-4 pt-4">
                    <label class="py-1 form__label">App ID</label>
                  </div>
                  <div class="col-8 pt-4">
                    <h5 class="py-1 d-block">{{ form.app_id }}</h5>
                  </div>

                  <div class="col-4 pt-4">
                    <label class="py-1 form__label">App Key</label>
                  </div>
                  <div class="col-8 pt-4">
                    <input
                      type="text"
                      class="input form__input form__input--md"
                      placeholder="Enter key"
                      style="height: 10px !important"
                      v-model="form.app_key"
                    />
                  </div>
                </div>
              </div>

              <div class="form__item mt-5">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!isSaving"
                  >
                    save change
                  </button>
                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="clearForm"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Uninstall, Enabled and Disabled Modal -->
    <DeleteModal :show="activateApp" @close="closeActivationModal">
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-6">
              {{ modalHead }} this App
            </h5>
            <p>{{ message }}</p>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--md modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeActivationModal"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--md save-changes fitrem"
                v-if="isActivating"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--md save-changes modal__confirm__button--delete fitrem"
                @click="activate"
                v-else
              >
                {{ modalHead }}
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>
  </main>
</template>
<script>
// import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/extension.service";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";
import { mapState } from "vuex";
import { GET_ALL_EXTENSION_APP } from "@/core/services/store/extensionapp.module";
export default {
  name: "Extensions",
  components: {
    // Multiselect,
    DeleteModal,
  },
  mounted() {
    this.getAllExtensionApp();
  },
  data() {
    return {
      content_header: {
        title: "Extension Apps",
      },
      isSaving: false,
      form: {
        app_key: "",
        app_id: "",
        associated_api_user: "",
        extension_point: "",
        url: "",
        loadUrl: "",
        name: "",
        description: "",
        provider: "",
        permissions: "",
        // username: "",
        // password: "",
        // confirmPassword: "",
        parameters: [
          {
            name: "string",
            value: "string",
          },
        ],
        httpVerb: "GET",
      },
      formErr: {
        password: false,
        confirmPassword: false,
        loadUrl: false,
      },
      // show: false,
      alert: {
        status: "",
        title: "",
        description: "",
        show: false,
      },
      activateApp: false,
      message: "",
      modalHead: "",
      isActivating: false,
      status: "",
      generalAppKey: "",
      errorMessage: "",
      isLoaded: false,
      loadXml: false,
    };
  },
  methods: {
    clearForm() {
      (this.show = false),
        (this.form = {
          app_key: "",
          app_id: "",
          associated_api_user: "",
          extension_point: "",
          url: "",
          loadUrl: "",
          name: "",
          description: "",
          provider: "",
          permissions: "",
          // username: "",
          // password: "",
          // confirmPassword: ""
          parameters: [
            {
              name: "string",
              value: "string",
            },
          ],
          httpVerb: "GET",
        });

      this.formErr = {
        password: false,
        confirmPassword: false,
        loadUrl: false,
      };
    },
    edit(data) {
      this.clearForm();
      this.form = data;
    },
    add() {
      this.clearForm();
    },
    loadExtension(e) {
      e.preventDefault();

      if (this.form.loadUrl) {
        this.loadXml = true;
        ApiService.get(this.form.loadUrl)
          .then((response) => {
            let data = response.data;
            this.isLoaded = true;
            let doc;
            let parser = new DOMParser();
            doc = parser.parseFromString(data, "text/xml"); // For Firefox, Chrome etc

            let x = doc.getElementsByTagName("app");
            for (let i = 0; i < x.length; i++) {
              this.form.app_id =
                x[i].getElementsByTagName("id")[0].childNodes[0].nodeValue;
              this.form.name =
                x[i].getElementsByTagName("name")[0].childNodes[0].nodeValue;
              this.form.provider =
                x[i].getElementsByTagName(
                  "provider"
                )[0].childNodes[0].nodeValue;
              this.form.description =
                x[i].getElementsByTagName(
                  "description"
                )[0].childNodes[0].nodeValue;
              let extension = x[i].getElementsByTagName("extensionpoint");
              this.form.permissions = extension[0]
                .getElementsByTagName("location")[0]
                .childNodes[0].nodeValue.replace("_", " ");
              this.form.extension_point = `${
                extension[0].getElementsByTagName("label")[0].childNodes[0]
                  .nodeValue
              } ${
                extension[0].getElementsByTagName("url")[0].childNodes[0]
                  .nodeValue
              }`;
              this.form.url =
                extension[0].getElementsByTagName(
                  "url"
                )[0].childNodes[0].nodeValue;
              // x[i].getElementsByTagName("Phone")[0].getAttribute('Link');
            }
            this.loadXml = false;
          })
          .catch(() => {
            this.loadXml = false;
            this.isLoaded = false;
            this.alertError(
              "The resource from this URL is not accessible or is not parsable."
            );
          });
      } else {
        this.alertError("App url is required.");
      }
    },
    validateForm() {
      let check = false;
      if (this.form.password.length < 10) {
        this.formErr.password = true;
        check = true;
        this.alertError("Password must be at least 10 characters.");
        return;
      } else {
        check = false;
        this.formErr.password = false;
      }

      if (this.form.password !== this.form.confirmPassword) {
        check = true;
        this.formErr.confirmPassword = true;
        this.alertError("Password do not match.");
        return;
      } else {
        check = false;
        this.formErr.confirmPassword = false;
      }

      return check;
    },
    getAllExtensionApp() {
      this.$store.dispatch(GET_ALL_EXTENSION_APP);
    },
    closeActivationModal() {
      this.activateApp = false;
      this.status = "";
      this.generalAppKey = "";
    },
    checkConfirmPassword() {
      if (this.form.password === this.form.confirmPassword) {
        this.formErr.confirmPassword = false;
      } else {
        this.formErr.confirmPassword = true;
      }
    },
    validatePassword() {
      if (this.form.password.length < 10) {
        this.formErr.password = true;
      } else {
        this.formErr.password = false;
      }
    },
    openActivationModal(status, key) {
      if (status == "uninstall") {
        this.message = "Please confirm that you want to uninstall this app";
        this.modalHead = "Uninstall";
      } else if (status == "enable") {
        this.message = "Please confirm that you want to enable this app";
        this.modalHead = "Enable";
      } else {
        this.message = "Please confirm that you want to disable this app";
        this.modalHead = "Disable";
      }
      this.activateApp = true;
      this.status = status;
      this.generalAppKey = key;
    },
    activate() {
      this.isActivating = true;
      if (this.status == "uninstall") {
        this.deleteApp();
      } else if (this.status == "enable") {
        this.enableApp();
      } else {
        this.disableApp();
      }
    },
    deleteApp() {
      ApiService.delete(`App/deleteapp/${this.generalAppKey}`)
        .then((response) => {
          this.getAllExtensionApp();
          if (response.data.status) {
            this.alertSuccess(response.data.message);
          } else {
            this.alertError(response.data.message);
          }
          this.isActivating = false;
          this.closeActivationModal();
        })
        .catch((err) => {
          this.isActivating = false;
          this.alertError(err.response.data.message);
        });
    },
    enableApp() {
      ApiService.patch(`App/enableapp/${this.generalAppKey}`)
        .then((response) => {
          this.getAllExtensionApp();
          if (response.data.status) {
            this.alertSuccess(response.data.message);
          } else {
            this.alertError(response.data.message);
          }
          this.isActivating = false;
          this.closeActivationModal();
        })
        .catch((err) => {
          this.isActivating = false;
          this.alertError(err.response.data.message);
        });
    },
    disableApp() {
      ApiService.patch(`App/disableapp/${this.generalAppKey}`)
        .then((response) => {
          this.getAllExtensionApp();
          if (response.data.status) {
            this.alertSuccess(response.data.message);
          } else {
            this.alertError(response.data.message);
          }
          this.isActivating = false;
          this.closeActivationModal();
        })
        .catch((err) => {
          this.isActivating = false;
          this.alertError(err.response.data.message);
        });
    },
    updateForm(e) {
      e.preventDefault();
      this.isSaving = true;
      let data = {
        ...this.form,
        parameters: [
          {
            name: "string",
            value: "string",
          },
        ],
        httpVerb: "GET",
      };

      const url = `App/editapp`;

      ApiService.patch(url, data)
        .then((response) => {
          this.getAllExtensionApp();
          this.isSaving = false;
          if (response.data.status == true) {
            this.alertSuccess(response.data.message);
          } else {
            this.alertError(response.data.message);
          }
          this.clearForm();
          document.getElementById("formEditButton").click();
        })
        .catch((err) => {
          this.isSaving = false;
          this.alertError(err.response.data.message);
        });
    },
    saveForm(e) {
      e.preventDefault();
      // if(this.formErr.password) {
      //   this.alertError("Password must be at least 10 characters.")
      //   return;
      // } else if(this.formErr.confirmPassword) {
      //   this.alertError("Confirm password do not match.")
      //   return;
      // } else {
      this.isSaving = true;
      let data = { ...this.form };
      const url = "App/createapp";

      ApiService.post(url, data)
        .then((response) => {
          this.getAllExtensionApp();
          this.isSaving = false;
          this.isLoaded = false;
          if (response.data.status == true) {
            this.alertSuccess(response.data.message);
          } else {
            this.alertError(response.data.message);
          }
          this.clearForm();
          document.getElementById("formCloseButton").click();
        })
        .catch((err) => {
          this.isSaving = false;
          this.alertError(err.response.data.message);
        });
      // }
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  computed: {
    ...mapState({
      extensionApps: (state) => {
        // console.log(state.extensionapp.allExtensionApp.generalapps)
        return state.extensionapp.allExtensionApp &&
          state.extensionapp.allExtensionApp.generalapps
          ? state.extensionapp.allExtensionApp.generalapps
          : [];
      },
      paginations: (state) => {
        return state.extensionapp.allExtensionApp;
      },
      loading: (state) => {
        return state.extensionapp.loading;
      },
    }),
  },
};
</script>

<style scoped>
.link--text:hover {
  color: #f58634 !important;
}
</style>
